import { createReducer } from "@reduxjs/toolkit"

import { MethodToSubjectDict } from "lib/leeruniek/interfaces"
import { getMethods } from "entities/methods/methods.actions"

type State = {
  methods: MethodToSubjectDict
  isLoading: boolean
}

type GetMethodsResponse = {
  payload: MethodToSubjectDict
}

const initialState: State = { methods: {}, isLoading: false }

export default createReducer(initialState, (builder) => {
  builder
    .addCase(getMethods.pending, (state: State) => {
      state.isLoading = true
    })
    .addCase(
      getMethods.fulfilled,
      (state: State, action: GetMethodsResponse) => {
        state.methods = action.payload
        state.isLoading = false
      },
    )
    .addCase(getMethods.rejected, (state: State) => {
      state.isLoading = false
    })
})
