import { createAsyncThunk } from "@reduxjs/toolkit"

import { GET } from "lib/core/api"
import { serializeError } from "lib/core/redux-tools.utils"
import { FullNote } from "lib/leeruniek/interfaces"

export const fetchNotes = createAsyncThunk<FullNote[], number>(
  "NOTES_FETCH",
  async (schoolId: number) => {
    const result = await GET("/all_notes", {
      query: { schoolId },
    })

    return result as FullNote[]
  },
  { serializeError },
)
