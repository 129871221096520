import moment from "moment"
import { isNil, range, sort, isEmpty } from "ramda"

import {
  MONTHS,
  CITO_ANALYSIS_WARNING_DELAY,
  YEAR_TIMES,
  YEAR_POSITIONS,
} from "lib/leeruniek/constants"

export const DATE_FORMAT = "DD-MM-YYYY"

export function getSchoolYear(time) {
  if (moment(time).month() >= MONTHS.AUGUST) {
    // Next School year;
    return time.getFullYear()
  }

  return time.getFullYear() - 1
}

export function getCurrentSchoolYear() {
  return getSchoolYear(new Date())
}

export function toDateFormat(strDateTime) {
  return moment(strDateTime).format(DATE_FORMAT)
}

export function toDateTimeFormat(strDateTime) {
  return moment(strDateTime).format("DD-MM-YYYY, HH:mm")
}

export function toYearIntervalFormat(calendarYear) {
  return `${calendarYear}/${calendarYear + 1}`
}

/**
 * Formats calendar year. Example: 2020 -> '21 / '22
 *
 * @param {number} calendarYear
 *
 * @returns {string}
 */
export function toShortYearIntervalFormat(calendarYear) {
  return `'${calendarYear % 100} / '${(calendarYear + 1) % 100}`
}

/*
 * Return the number of days tha elapsed since the given date (as a string).
 *
 * @returns {number}
 */
export function getDaysFrom(dateStr) {
  if (isNil(dateStr)) {
    return
  }
  const ret = moment().diff(moment(dateStr), "days")
  if (Number.isNaN(ret)) {
    return 0
  }
  return ret
}

export const isWithinCitoAnalysisPeriod = (date) =>
  !isNil(date) &&
  moment(date)
    .add(...CITO_ANALYSIS_WARNING_DELAY)
    .isAfter(moment())

export const isWithinSchoolYearStartPeriod = () => {
  const currentSchoolYear = getCurrentSchoolYear()
  const threshold = new Date(`${currentSchoolYear}-10-01`)
  const now = new Date()
  return now < threshold
}

// Change "2018/2019" into 2018
export const yearLongToCalendarYear = (yearLong) =>
  parseInt(yearLong.slice(0, 4), 10)

export const findPreviousScore = (
  currentScore,
  scores = [],
  yearTimeFieldName,
  yearGradeFieldName,
  subjectFieldName,
) => {
  /*
   * Return the yearclassTestMomentIds attached to the score in
   * the "previous" period by this rule:
   * 1. For B test previous score is a score with E period from previous grade.
   * To account for COVID-19 pandemic tests, we also allow the previous score to be the M score from previous grade, if the E test is not available.
   * 2. For M test previous score is a score with B period from current grade. If it doesn't exist then a score with E period from previous grade.
   * 3. E scrore can have as previous only M score from current grade.
   *
   */

  const filteredScores = scores.filter(
    (score) => score[subjectFieldName] === currentScore[subjectFieldName],
  )
  const scoreFromGrade = (gradePosition, yearTime) => (score) => {
    const gradeCondition =
      gradePosition === YEAR_POSITIONS.PREV
        ? parseInt(score[yearGradeFieldName], 10) ===
          parseInt(currentScore[yearGradeFieldName], 10) - 1
        : score[yearGradeFieldName] === currentScore[yearGradeFieldName]
    const dateTakenCondition =
      isNil(score.dateTaken) ||
      moment(score.dateTaken).isBefore(moment(currentScore.dateTaken))
    return (
      score[yearTimeFieldName] === yearTime &&
      gradeCondition &&
      dateTakenCondition
    )
  }

  switch (currentScore[yearTimeFieldName]) {
    case YEAR_TIMES.COVID:
      return (
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.PREV, YEAR_TIMES.END),
        ) ||
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.PREV, YEAR_TIMES.MIDDLE),
        )
      )
    case YEAR_TIMES.BEGINNING:
      return (
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.CURRENT, YEAR_TIMES.COVID),
        ) ||
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.PREV, YEAR_TIMES.END),
        ) ||
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.PREV, YEAR_TIMES.MIDDLE),
        )
      )
    case YEAR_TIMES.MIDDLE:
      return (
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.CURRENT, YEAR_TIMES.BEGINNING),
        ) ||
        filteredScores.find(
          scoreFromGrade(YEAR_POSITIONS.CURRENT, YEAR_TIMES.COVID),
        ) ||
        filteredScores.find(scoreFromGrade(YEAR_POSITIONS.PREV, YEAR_TIMES.END))
      )
    case YEAR_TIMES.END:
      return filteredScores.find(
        scoreFromGrade(YEAR_POSITIONS.CURRENT, YEAR_TIMES.MIDDLE),
      )
  }
}

export const getYearPosition = (year, currentSchoolYear) => {
  switch (year) {
    case currentSchoolYear:
      return YEAR_POSITIONS.CURRENT
    case currentSchoolYear - 1:
      return YEAR_POSITIONS.PREV
    case currentSchoolYear + 1:
      return YEAR_POSITIONS.NEXT
    default:
      return YEAR_POSITIONS.OTHER
  }
}

export function getMostRecentYear(yearclasses, defaultYear) {
  if (!isNil(yearclasses) && !isEmpty(yearclasses)) {
    const sortedYearclasses = sort(
      (ya, yb) => yb.calendarYear - ya.calendarYear,
      yearclasses,
    )
    return Math.max(sortedYearclasses[0].calendarYear, defaultYear)
  }

  return defaultYear
}

export function getRecentYears(yearclasses, defaultYear) {
  const mostRecentYear = getMostRecentYear(yearclasses, defaultYear)

  return range(defaultYear - 2, mostRecentYear + 1)
}

export function getTestTime(score) {
  return `${score.testTimeExpected}${score.classYear}`
}

export const getExpectedYearTimes = (
  hasCovidScores = false,
  hasBeginningScores = false,
) => {
  if (hasCovidScores) {
    return [YEAR_TIMES.COVID, YEAR_TIMES.MIDDLE, YEAR_TIMES.END]
  }

  if (hasBeginningScores) {
    return [YEAR_TIMES.BEGINNING, YEAR_TIMES.END]
  }

  return [YEAR_TIMES.MIDDLE, YEAR_TIMES.END]
}

/**
 * Formats calendar year. Example: 2020 -> '21 / '22
 *
 * @param {number} calendarYear
 *
 * @returns {string}
 */
export const formatCalendarYear = (calendarYear) => {
  return `'${calendarYear % 100} / '${(calendarYear + 1) % 100}`
}
