import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  questions: [],
  domains: [],
  isQuestionsLoaded: false,
  isDomainsLoaded: false,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_SE_QUESTIONS_REQUEST", (state) => {
      state.isQuestionsLoaded = false
      state.isDomainsLoaded = false
    })
    .addCase("GET_SE_QUESTIONS_SUCCESS", (state, action) => {
      const [domains, questions] = action.payload

      state.questions = questions
      state.domains = domains
      state.isQuestionsLoaded = true
      state.isDomainsLoaded = true
    })
})
