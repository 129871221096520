import { createReducer } from "@reduxjs/toolkit"

export const initialState = {
  permissions: [],
  isLoading: false,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_PERMISSIONS_REQUEST", (state) => {
      state.isLoading = true
    })
    .addCase("GET_PERMISSIONS_SUCCESS", (state, action) => {
      state.permissions = action.payload
      state.isLoading = false
    })
    .addCase("SET_TEACHER_PERMISSIONS_SUCCESS", (state, action) => {
      const { userId, groupIds } = action.payload

      const existingUserPermission = state.permissions.find(
        (permission) => permission.userId === userId,
      )
      const userGroupIds = existingUserPermission
        ? existingUserPermission.groupIds.concat(groupIds)
        : groupIds

      return {
        ...state,
        permissions: [
          ...state.permissions.filter(
            (permission) => permission.userId !== userId,
          ),
          {
            userId,
            groupIds: userGroupIds,
          },
        ],
      }
    })
    .addCase("REMOVE_TEACHER_PERMISSIONS_SUCCESS", (state, action) => {
      const { userId, groupIds } = action.payload

      state.permissions = [
        ...state.permissions.map((permission) => {
          if (permission.userId === userId) {
            return {
              userId: permission.userId,
              groupIds: [
                ...permission.groupIds.filter((id) => !groupIds.includes(id)),
              ],
            }
          }

          return permission
        }),
      ]
    })
})
