import { createReducer } from "@reduxjs/toolkit"
import { path, find, propEq, reject } from "ramda"

export const initialState = {
  currentPupil: null,
  isPupilLoading: false,
  isNoteLoading: false,
  removedPrintSectionCount: 0,
}
import { noteTypeMapping } from "entities/pupils/pupils.actions"
import { TEST_TYPES } from "lib/leeruniek/constants"

/*
 *
 * newObject - object containing key and other properties
 * currentList - list containing objects with key and other properties
 *
 * Based on the key either add or update newObject
 * in currentList.
 *
 */
export const upsertObjectInList = (newObject, currentList, uniquePath) => {
  if (!uniquePath) {
    uniquePath = ["id"]
  }

  if (!newObject || !path(uniquePath)(newObject)) {
    return currentList
  }

  let newList
  if (
    currentList.find(
      (currentObject) =>
        path(uniquePath)(currentObject) === path(uniquePath)(newObject),
    )
  ) {
    newList = currentList.map((currentObject) =>
      path(uniquePath)(currentObject) === path(uniquePath)(newObject)
        ? newObject
        : currentObject,
    )
  } else {
    newList = [...currentList, newObject]
  }
  return newList
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_PUPIL_REQUEST", (state) => {
      state.isPupilLoading = true
    })
    .addCase("GET_PUPIL_SUCCESS", (state, action) => {
      if (!action.payload || !action.payload.id) {
        return { ...state, isPupilLoading: false }
      }

      state.isPupilLoading = false
      state.currentPupil = action.payload
    })
    .addCase("GET_PUPIL_FAILURE", (state) => {
      state.isPupilLoading = false
    })
    .addCase("UPDATE_PUPIL_OBSERVATION_SUCCESS", (state, action) => {
      if (!state.currentPupil) {
        return
      }
      const observations = upsertObjectInList(
        action.payload,
        state.currentPupil.observations,
      )

      state.currentPupil = {
        ...state.currentPupil,
        observations,
      }
    })
    .addCase("CREATE_PUPIL_METHOD_SCORE_NOTE_SUCCESS", (state, action) => {
      return handleNoteUpsert(state, action, TEST_TYPES.METHOD)
    })
    .addCase("UPDATE_PUPIL_METHOD_SCORE_NOTE_SUCCESS", (state, action) => {
      return handleNoteUpsert(state, action, TEST_TYPES.METHOD)
    })
    .addCase("DELETE_PUPIL_METHOD_SCORE_NOTE_SUCCESS", (state, action) => {
      return handleNoteDelete(state, action, TEST_TYPES.METHOD)
    })

    .addCase("CREATE_PUPIL_NATIONAL_SCORE_NOTE_SUCCESS", (state, action) => {
      return handleNoteUpsert(state, action, TEST_TYPES.NATIONAL)
    })

    .addCase("UPDATE_PUPIL_NATIONAL_SCORE_NOTE_SUCCESS", (state, action) => {
      return handleNoteUpsert(state, action, TEST_TYPES.NATIONAL)
    })

    .addCase("DELETE_PUPIL_NATIONAL_SCORE_NOTE_SUCCESS", (state, action) => {
      return handleNoteDelete(state, action, TEST_TYPES.NATIONAL)
    })
    .addCase("INCREMENT_REMOVED_PRINT_SECTION_COUNT", (state) => {
      state.removedPrintSectionCount = state.removedPrintSectionCount + 1
    })
    .addCase("DECREMENT_REMOVED_PRINT_SECTION_COUNT", (state) => {
      state.removedPrintSectionCount =
        state.removedPrintSectionCount > 0
          ? state.removedPrintSectionCount - 1
          : 0
    })
    .addCase("RESET_REMOVED_PRINT_SECTION_COUNT", (state) => {
      state.removedPrintSectionCount = initialState.removedPrintSectionCount
    })
})

const UPSERT_ACTION_TYPE = "UPSERT_ACTION_TYPE"
const DELETE_ACTION_TYPE = "DELETE_ACTION_TYPE"

/*
 * This is a generic function which searches for the notes depending on
 * the noteType and either inserts/updates (upsert) them or deletes them
 * depending on the actionToApplyOnNotes parameter.
 *
 * state - store slice
 * action - action with payload
 * noteType - specifies which noteType to use from the noteTypeMapping
 * actionToApplyOnNotes - specifies upsert or delete to notes
 *
 */
const handleNoteAction = (state, action, noteType, actionToApplyOnNotes) => {
  if (!state.currentPupil) {
    return state
  }

  /*
   *
   * currentPupil state looks like:
   {
    ...

     scores: [
     ^^^^^^
   scoresStoreKey

      {
        ...
        id: 20665500,
        roman: 'IV'
        notes: [
         {
           id: 3170046,
               ^^^^^^^
               action.payload.id


           citoTestScoreId: 20665500,
           ^^^^^^^^^^^^^^^^
             scoreIdKey
           ...
         },
        ]
      },
      ]
    }
    */

  //scoresStoreKey refers to e.g., scores/methodScore in the currentPupil store
  //scoreIdKey refers to e.g., citoTestScoreId/methodTestScoreId in the note payload
  const { scoresStoreKey, scoreIdKey } = noteTypeMapping[noteType]

  let scores = path(["currentPupil", scoresStoreKey])(state)
  if (!scores) {
    return state
  }
  let score = find(propEq(action.payload[scoreIdKey], "id"))(scores)
  let getScoreByParentId = false
  if (!score) {
    // fallback to search for parent id when score does not have its own id
    score = find((score) => {
      return (
        !score.id &&
        score.parent &&
        score.parent.id === action.payload[scoreIdKey]
      )
    })(scores)
    getScoreByParentId = true
  }
  if (!score) {
    return state
  }

  let newNotes
  if (actionToApplyOnNotes === UPSERT_ACTION_TYPE) {
    newNotes = upsertObjectInList(action.payload, score.notes)
  } else if (actionToApplyOnNotes === DELETE_ACTION_TYPE) {
    newNotes = reject(propEq(action.payload.id, "id"), score.notes)
  }
  const newScore = { ...score, notes: newNotes }

  let uniquePath
  if (getScoreByParentId) {
    uniquePath = ["parent", "id"]
  } else {
    uniquePath = ["id"]
  }
  scores = upsertObjectInList(newScore, scores, uniquePath)

  state.currentPupil = {
    ...state.currentPupil,
    [scoresStoreKey]: scores,
  }
}

const handleNoteUpsert = (state, action, noteType) => {
  return handleNoteAction(state, action, noteType, UPSERT_ACTION_TYPE)
}

const handleNoteDelete = (state, action, noteType) => {
  return handleNoteAction(state, action, noteType, DELETE_ACTION_TYPE)
}
