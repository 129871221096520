import { createReducer } from "@reduxjs/toolkit"

export const initialState = {
  isYeargradeLoading: true,
  currentYeargrade: null,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_YEARGRADE_REQUEST", (state) => {
      state.isYeargradeLoading = true
    })
    .addCase("GET_YEARGRADE_SUCCESS", (state, action) => {
      if (!action.payload || !action.payload.id) {
        return { ...state, isYeargradeLoading: false }
      }

      state.isYeargradeLoading = false
      state.currentYeargrade = {
        ...action.payload,
      }
    })
    .addCase("GET_YEARGRADE_FAILURE", (state) => {
      state.isYeargradeLoading = false
    })
})
